import React from "react";
import SEO from "../components/seo";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Logo from "../resources/images/Elliate_logo_white.svg"

export default function Kontakt() {





  const [state, setState] = React.useState({
    
    checkedB: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <div className="container">
        <nav>
          
        <AniLink cover direction="right" to="/" bg="#000" duration={1}><div className="logo"><img width="154.75" height="36.44" src={Logo}  alt="Logo"></img></div></AniLink>
          <div className="menu">
            <input type="checkbox" id="nav" className="hidden"/>
            <label for="nav" className="nav-open"><i></i><i></i><i></i></label>
            <div className="nav-container">
              <ul>
                <li><div className="subnav">Služby
                  <div className="submenu-content">
                    <AniLink cover direction="left" to="/web-aplikacie" bg="#000" duration={1}>Web Aplikácie<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/mobilne-aplikacie" bg="#000" duration={1}>Mobilné Aplikácie<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/umela-inteligencia" bg="#000" duration={1}>Umelá Inteligencia<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/big-data" bg="#000" duration={1}>Big Data<span className="separator"> --</span></AniLink>
                    <AniLink cover direction="left" to="/outsource" bg="#000" duration={1}>Outsourcing<span className="separator"> --</span></AniLink>
                  </div></div></li>
                
                <li><AniLink cover direction="left" to="/referencie" bg="#000" duration={1}>Referencie</AniLink></li>
                <li><AniLink cover direction="left" to="/ako-to-robime" bg="#000" duration={1}>Ako to robíme</AniLink></li>
                <li><AniLink cover direction="left" to="/o-nas" bg="#000" duration={1}>O nás</AniLink></li>
                <li><AniLink cover direction="left" to="/kontakt" bg="#000" duration={1}>Kontakt</AniLink></li>

              </ul>
            </div>
          </div>
        </nav>
        </div>
      <SEO title="Kontakt" />
      
     
      <div className="subhero">   
      <div className="hero">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="title"><h1>Vytvorme spolu <span style={{color: "#F94166"}}>niečo výnimočné.</span></h1></div>
            <p>Ozvite sa nám a my Vám pomôžeme nájsť riešenie v digitálnom svete.</p>
        
          </div>
          </div>
        </div>
      </div> 
      </div>  
    

      <div className="section-pd section-pd-bottom white-bg ">
        <div className="container">
          
          <div className="row mt40">
            <div className="col-4 section-heading-black">
              <h2>Napíšte nám!</h2>
              <div className="contact-info">
                <p><a href="mailto:hello@elliate.com">hello@elliate.com</a></p>
                <p><a href="tel:+421940634576">+421 940 634 576</a></p>
                
                <p>
                <strong>Elliate s.r.o.</strong><br></br>

                Javorová 373/6, 04001 Košice <br></br> Slovakia</p>
                
              </div>
            </div>
            <div className="col-8">
            <form method="post" action="/success" netlify-honeypot="bot-field" data-netlify="true" name="contact">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div className="row">
                <div className="col-6">
                   
                  <TextField fullWidth name="name" id="name" label="Meno" required />   
                    
                </div>
                <div className="col-6">
                <TextField fullWidth name="email" id="email" label="E-mail" required />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                <TextField fullWidth name="company" id="company" label="Spoločnosť" required />
                </div>
                <div className="col-6">
                <TextField fullWidth name="subject" id="subject" label="Predmet" required />
                  
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                <TextField fullWidth name="message" id="message" label="Správa" rows={4} multiline required />
                  
                </div>
              </div>
              
              <div className="row">
                <div className="col-12 inline">
               
                

                <FormControlLabel control={<Checkbox name="vop" color="primary" required/>} label="Súhlasím so spracovaním osobných údajov *" />
                  
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button type="submit" className="send-btn">Odoslať formulár</button>
                </div>
              </div>
              
            </form>
            </div>
            
        </div>
        

      
        </div>
      </div>
      <div className="copyright-holder">
        <div className="container">
          <div className="copyright">
          <div className="logo">
            <img src={Logo} alt=""></img>
          </div>
          <div className="copy">© {new Date().getFullYear()} Elliate s.r.o.</div>
          <div className="links">
          <AniLink cover direction="left" to="/page-2" bg="#000" duration={1} >
            Ochrana osobných údajov
            </AniLink>
          </div>
          </div>
          </div>
        </div>
     </>
  );

}
